import styled from '@emotion/styled'
import { useContext, useState } from 'react'
import { FormContext, type FormContextType } from './context'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input/input'
import 'react-phone-number-input/style.css'
import { GreenAlert } from '../../components/alerts/GreenAlert'

const FormContainer = styled.div`
`

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
`

const FormRow = styled.div`
  margin-top: 16px;
`

const FormInput = styled.input`
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
`

const FormPhoneInput = styled(PhoneInput)`
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
`

const TitleTextStyle = styled.h2`
  font-size: 24px;
`

const FormLabel = styled.label`
  font-size: 16px;
  font-weight: bold;
`

const NAME_REGEX = /^[A-Za-z ñÑáéíóúÁÉÍÓÚüÜ]*$/

export const Form = () => {
    const formContext: FormContextType = useContext(FormContext)
    const {
        nameValue,
        setNameValue,
        phoneValue,
        setPhoneValue,
        submitSuccess,
        setSubmitSuccess
    } = formContext
    const [phoneError, setPhoneError] = useState(false)
    const onShowModalClick = () => {
        const isValid = isValidPhoneNumber(phoneValue)
        if (!isValid) {
            setPhoneError(true)
            return
        }
        formContext.setShowModal(!formContext.showModal)
    }

    const onNumberChange = (event: any) => {
        if (!event) {
            setPhoneError(false)
            return
        }
        if (event.length > 13) {
            setPhoneError(true)
            return
        }
        setPhoneError(false)
        setPhoneValue(event)
    }

    const onNameChange = (event: any) => {
        if (NAME_REGEX.test(event.target.value)) {
            setNameValue(event.target.value)
        }
    }

    return (
        <FormContainer>
            <FormWrapper>
                <TitleTextStyle className='text-center'>¡Apóyanos y participa en la rifa!</TitleTextStyle>
                <FormRow>
                    <FormLabel htmlFor="name">Nombre</FormLabel>
                    <FormInput
                        type="text"
                        id="name"
                        value={nameValue}
                        onChange={onNameChange}
                        maxLength={50}
                    />
                </FormRow>

                <FormRow>
                    <FormLabel htmlFor="contact_number">Número de contacto</FormLabel>
                    <FormPhoneInput
                        defaultCountry='MX'
                        name="contact_number"
                        id="contact_number"
                        value={phoneValue}
                        maxLength={12}
                        onChange={onNumberChange}/>
                    {phoneError && <span className='text-red-500'>Número invalido</span>}
                </FormRow>

                <FormRow className='self-center'>
                    <button
                        type="button"
                        className="bg-salvando-michis text-white border border-blue-700 disabled:bg-blue-400 disabled:cursor-not-allowed hover:bg-salvando-michis-hover focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                        onClick={onShowModalClick}
                        disabled={phoneError || !nameValue || !phoneValue}
                    >
                        Elegir números
                    </button>
                </FormRow>
                {submitSuccess && <GreenAlert onCloseClick={() => { setSubmitSuccess(false) }}>
                    <span>¡Números guardados, gracias por participar!</span>
                </GreenAlert>}
            </FormWrapper>
        </FormContainer>
    )
}
