import PropTypes from 'prop-types'
import { useContext, useMemo, useState } from 'react'

import { NumberSelector } from './number_selector'
import { FormContext, type FormContextType, type UsedItemType, type UsedNumberType } from './context'
import { createEntryNumbers, type EntryData } from './api'
import { RedAlert } from '../../components/alerts/RedAlert'

export const SelectorModal = () => {
    const formContext: FormContextType = useContext(FormContext)
    if (!formContext.showModal) {
        return null
    }

    const [isLoading, setIsLoading] = useState(false)

    const {
        nameValue,
        setNameValue,
        phoneValue,
        setPhoneValue,
        setSubmitError,
        submitError,
        setSubmitSuccess
    } = formContext

    const usedNumbers = useMemo(() =>
        Object.entries(formContext.usedNumbers).filter(([, value]) => !!value.selected).map(([key]) => Number(key)),
    [formContext.usedNumbers]
    )
    const onCloseClick = () => {
        formContext.setUsedNumbers(Object.values(formContext.usedNumbers)
            .filter(number => number.selected)
            .reduce((acc: UsedItemType, number: UsedNumberType) => {
                acc[number.number] = {
                    number: number.number,
                    selected: false,
                    is_verified: false
                }
                return acc
            }, { ...formContext.usedNumbers }))

        formContext.setShowModal(false)
    }

    const onAcceptClick = () => {
        const data: EntryData = {
            name: nameValue,
            telephone_number: phoneValue,
            numbers: usedNumbers
        }
        setIsLoading(true)
        createEntryNumbers(data)
            .then(() => {
                formContext.setUsedNumbers(Object.values(formContext.usedNumbers).reduce((acc: UsedItemType, number: UsedNumberType) => {
                    acc[number.number] = {
                        number: number.number,
                        selected: false,
                        is_verified: false
                    }
                    return acc
                }, { ...formContext.usedNumbers }))
                setPhoneValue('')
                setNameValue('')
                formContext.setShowModal(false)
                setSubmitSuccess(true)
            })
            .catch((error) => {
                if (error?.response?.data) {
                    setSubmitError(error.response.data.message)
                    return
                }
                setSubmitError('Hubo un problema al guardar tus números. Intenta de nuevo mas tarde')
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <div>
            <div
                id="defaultModal"
                className="flex justify-center items-center fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
            >
                <div className="relative w-full max-w-2xl max-h-full">
                    {/* Modal content */}
                    <div className="relative bg-white rounded-lg shadow">
                        {/* Modal header */}
                        <div className="flex items-start justify-between p-4 border-b rounded-t">
                            <h3 className="text-xl font-semibold text-gray-900">
                                Elige hasta 5 números para participar
                            </h3>
                            <button
                                type="button"
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                                onClick={onCloseClick}
                            >
                                <svg
                                    aria-hidden="true"
                                    className="w-5 h-5"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-6 space-y-6">
                            {submitError && <RedAlert onCloseClick={() => { setSubmitError(undefined) }}>
                                <span>{submitError}</span>
                            </RedAlert>}
                            <div className='text-center'>
                                <span>Números elegidos:</span>
                                <span className='font-bold'> {usedNumbers.length ? usedNumbers.join(',') : 'Ninguno'}</span>
                            </div>
                            <NumberSelector />
                        </div>
                        {/* Modal footer */}
                        <div
                            className="flex items-center justify-center p-6 space-x-2 border-t border-gray-200 rounded-b">
                            <button
                                type="button"
                                className="w-1/2 mx-10 text-white bg-salvando-michis hover:bg-salvando-michis-hover focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                onClick={onAcceptClick}
                            >
                                {isLoading ? 'Guardando...' : 'Confirmar'}
                            </button>
                            <button
                                type="button"
                                className="w-1/2 mx-10 text-white bg-gray-500 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
                                onClick={onCloseClick}
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-gray-900 bg-opacity-50 fixed inset-0 z-40"></div>
        </div>

    )
}

SelectorModal.propTypes = {
    onAccept: PropTypes.func
}
