import { type Theme } from '@emotion/react'

const resolutions = {
    extraSmall: '0',
    small: '768px'
}

export const FONT = '"Poppins", sans-serif;'

const handheld = `@media (min-width: ${resolutions.extraSmall})`
const desktop = `@media (min-width: ${resolutions.small})`

const MAIN_THEME: Theme = {
    mediaQueries: {
        handheld,
        desktop
    },

    fonts: {
        body: FONT
    }
}

export default MAIN_THEME
