import { useContext, useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import { FormContext, type UsedItemType, type UsedNumberType } from './context'
import { getEntryNumbers } from './api'

const SelectorContainer = styled.div`
  height: 50vh;
  display: grid;
  overflow-y: scroll;
`

const SelectorItem = styled.div`
`

export const NumberSelector = () => {
    const ROW_WIDTH = 10
    const COLUMN_HEIGHT = 30

    const ROW_ARRAY = useMemo(() => Array.from(Array(ROW_WIDTH).keys()), [])
    const COLUMN_ARRAY = useMemo(() => Array.from(Array(COLUMN_HEIGHT).keys()), [])
    const { usedNumbers, setUsedNumbers } = useContext(FormContext)

    useEffect(() => {
        getEntryNumbers()
            .then((numbers) => {
                const newUsedNumbers = numbers.reduce((acc: UsedItemType, number: UsedNumberType) => {
                    acc[number.number] = {
                        number: number.number,
                        selected: false,
                        is_verified: number.is_verified
                    }
                    return acc
                }, { ...usedNumbers })
                setUsedNumbers(newUsedNumbers)
            })
            .catch(() => {})
    }, [setUsedNumbers])
    const onItemClick = (index: number) => {
        const selectedIndexCount = Object.values(usedNumbers).filter((value) => value.selected).length
        const selected = !usedNumbers[index]?.selected

        if (usedNumbers[index]?.is_verified) {
            return
        }
        if (selected && selectedIndexCount >= 5) {
            return
        }
        setUsedNumbers({
            ...usedNumbers,
            [index]: {
                number: index,
                selected,
                is_verified: false
            }
        })
    }

    let renderIndex = 0

    const colorChooser = (number: number) => {
        if (usedNumbers[number]?.selected) {
            return 'bg-gray-300'
        }
        if (usedNumbers[number]?.is_verified) {
            return 'bg-red-500'
        }
        return 'bg-gray-100'
    }

    return (
        <SelectorContainer className="gap-1 grid-cols-5">
            {
                ROW_ARRAY.map(() => {
                    return COLUMN_ARRAY.map(() => {
                        renderIndex += 1
                        const currentIndex = renderIndex
                        return (
                            <SelectorItem
                                className={`select-none cursor-pointer text-center flex justify-center items-center w-16 md:w-full h-16 ${colorChooser(renderIndex)}`}
                                key={`${renderIndex}`}
                                data-index={renderIndex}
                                onClick={() => {
                                    onItemClick(currentIndex)
                                }}
                            >
                                <span>{renderIndex}</span>
                            </SelectorItem>
                        )
                    })
                })
            }
        </SelectorContainer>
    )
}
