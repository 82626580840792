import styled from '@emotion/styled'
import { useEffect, useState } from 'react'

const CarouselContainer = styled.div``

const ImageContainer = styled.div<{ currentImage: string }>`
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(${props => props.currentImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`

const Images = [
    '/carousel/lindo-perro-parado-escaleras-banner_optimized.webp',
    '/carousel/perro-callejero-local-turco-ojos-tristes-mirando-camara-calle_optimized.webp',
    '/carousel/primer-plano-gatos_optimized.webp',
    '/carousel/primer-plano-hocicos-lindo-perro-gato-sentado-mejilla-mejilla_optimized.webp'
]

export const Carousel = () => {
    const [currentImage, setCurrentImage] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentImage === Images.length - 1) {
                setCurrentImage(0)
            } else {
                setCurrentImage(currentImage + 1)
            }
        }, 5000)

        return () => { clearInterval(interval) }
    })

    return (
        <CarouselContainer className='self-start'>
            {Images.map((image, index) => (
                <ImageContainer className={`animate__animated animate__slow ${currentImage === index ? 'block animate__fadeIn' : 'animate__fadeOut'}`} key={index} currentImage={image} />
            ))}
        </CarouselContainer>
    )
}
